import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { can } from 'rbac'

import UserContext, { UserContextProvider } from './contexts/UserContext'
import GameContext, { GameContextProvider } from './contexts/GameContext'

import Admin from './pages/Admin'
import Build from './pages/Build'
import Calculator from './pages/Calculator'
import StatsCalc from './pages/Calculator/StatsCalc'
import CompEdit from './pages/Build/CompEdit'
import BuildSubmitted from './pages/Build/BuildSubmitted'
import Builds from './pages/Builds'
import Event from './pages/Event'
import Events from './pages/Events'
import Home from './pages/Home'
import Login from './pages/Login'
import Map from './pages/Map'
import Navbar from './components/Navbar'
import Profile from './pages/Profile'
import Signup from './pages/Login/Signup'
import Sources from './pages/Sources'
import Unit from './pages/Unit'
import Units from './pages/Units'

import AdSlot from './components/Ads/AdSlot'
import CreateComp from './components/Comps/CreateComp'
import Footer from './components/Footer'

import { ThemeProvider } from 'styled-components'
import { lightTheme, darkTheme, GlobalStyles } from './theme'

import 'flexboxgrid/css/flexboxgrid.min.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'leaflet/dist/leaflet.css'
import './App.css'

const useTheme = () => {
  const [theme, setTheme] = useState(
    window.localStorage.getItem('theme') || 'light'
  )

  const toggleMode = () => {
    const mode = window.localStorage.getItem('theme')
    const newMode = !mode || mode === 'light' ? 'dark' : 'light'
    window.localStorage.setItem('theme', newMode)
    setTheme(newMode)
  }

  useEffect(() => {
    setTheme(window.localStorage.getItem('theme'))
  }, [])

  return [theme, toggleMode]
}

function App() {
  const [theme, toggleTheme] = useTheme()

  return (
    <div>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
        <UserContextProvider>
          <GameContextProvider>
            <BrowserRouter>
              <GlobalStyles />
              <Navbar toggleTheme={toggleTheme} theme={theme} />
              <div className='App'>
                <UserContext.Consumer>
                  {({ user }) => {
                    return (
                      <GameContext.Consumer>
                        {({ game }) => {
                          return (
                            <Routes>
                              {can(user, 'COMPS:APPROVE') && (
                                <Route path='admin' element={<Admin />} />
                              )}
                              <Route
                                path='builds/submitted'
                                element={<BuildSubmitted />}
                              />
                              <Route
                                path={`${game.getText('build')}/:id`}
                                element={<Build />}
                              />
                              <Route
                                path='build/:id/edit'
                                element={<CompEdit />}
                              />
                              {!game.has('UNITS:DISABLED') && (
                                <Route
                                  path={`${game.getText('unit')}s/:id`}
                                  element={<Unit />}
                                />
                              )}
                              {!game.has('UNITS:DISABLED') && (
                                <Route
                                  path={`${game.getText('unit')}s`}
                                  element={<Units />}
                                />
                              )}
                              {game.unitPages &&
                                game.unitPages.map((unitPage) => {
                                  return (
                                    <React.Fragment
                                      key={`unitPage-${unitPage.type}`}
                                    >
                                      <Route
                                        path={`${unitPage.type}/:id`}
                                        element={<Unit />}
                                      />
                                      <Route
                                        path={`${unitPage.type}`}
                                        element={<Units type={unitPage.type} />}
                                      />
                                    </React.Fragment>
                                  )
                                })}
                              <Route
                                path='breeding-calculator'
                                element={<Calculator />}
                              />
                              <Route
                                path='iv-calculator'
                                element={<StatsCalc />}
                              />
                              <Route path='sources' element={<Sources />} />
                              <Route path='events/:id' element={<Event />} />
                              <Route path='events' element={<Events />} />
                              <Route path='map/:id' element={<Map />} />
                              <Route path='map' element={<Map />} />
                              <Route
                                path={`${game.getText('build')}s`}
                                element={<Builds />}
                              />
                              <Route
                                path={`${game.getText('build')}s/create`}
                                element={<CreateComp />}
                              />
                              <Route path='profile/:id' element={<Profile />} />
                              <Route path='login' element={<Login />} />
                              <Route path='signup' element={<Signup />} />
                              <Route index element={<Builds />} />
                            </Routes>
                          )
                        }}
                      </GameContext.Consumer>
                    )
                  }}
                </UserContext.Consumer>
              </div>
              <Footer />
              <AdSlot name='anchor' />
            </BrowserRouter>
          </GameContextProvider>
        </UserContextProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
