import { useContext } from 'react'
import { HiTrash } from 'react-icons/hi'

import GameContext from '../../contexts/GameContext'

import { StyledInput, StyledSelect } from '../Styled'
import ImageComp from '../Image'

const CreateUnits = ({ units, setUnits }) => {
  const { game } = useContext(GameContext)
  return (
    <div>
      {units.map((unit, idx) => {
        return (
          <div className='comp-create-unit' key={idx}>
            <div className='comp-create-unit-left'>
              <StyledInput
                value={unit.amount || ''}
                onChange={(e) => {
                  units[idx].amount = parseInt(e.target.value)
                  setUnits([...units])
                }}
                placeholder='amount'
                className='comp-create-amount'
              />
              <ImageComp
                src={
                  unit.img.includes('http')
                    ? unit.img
                    : `https://${game.cdn}${
                        unit.img || `/units/${unit.value}.png`
                      }`
                }
                className='build-unit-img'
              />
              {unit.label}
              {unit.properties &&
                unit.properties.map((property) => {
                  return (
                    <StyledSelect
                      value={
                        property.string
                          ? property.options.find(
                              (opt) => opt.value === property.string
                            )
                          : ''
                      }
                      options={property.options}
                      onChange={(option) => {
                        if (!units[idx].propValues) units[idx].propValues = {}
                        units[idx].propValues[property.id] = option.value
                        setUnits([...units])
                      }}
                      placeholder={property.name}
                      className='comp-create-property'
                    />
                  )
                })}
              {unit.tags &&
                unit.tags
                  .filter((tag) => tag.format?.filter)
                  .map((tag) => {
                    return (
                      <div>
                        <img
                          src={`https://${game.cdn}${tag.img}`}
                          className='comp-create-tag-img'
                        />
                      </div>
                    )
                  })}
            </div>
            <div className='comp-create-unit-right'>
              <button
                type='button'
                className='btn-icon comp-step-trash'
                onClick={() => {
                  units.splice(idx, 1)
                  setUnits([...units])
                }}
              >
                <HiTrash />
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CreateUnits
