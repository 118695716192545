import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

import GameContext from '../../contexts/GameContext'
import http from '../../services/http'

import Helmet from '../../components/Helmet'
import Comps from '../../components/Comps'
import {
  ButtonLink,
  StyledSelect,
  StyledTabLink,
} from '../../components/Styled'

import { FiPlusCircle } from 'react-icons/fi'

const Builds = () => {
  const { game } = useContext(GameContext)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(51)
  const [comps, setComps] = useState([])

  const typeOptions = game.builds?.types || []

  const [type, setType] = useState(typeOptions ? typeOptions[0] : {})
  const location = useLocation()
  const navigate = useNavigate()
  const query = qs.parse(location.search.substring(1))
  const hasNewTags = query.tags ? query.tags.length : 0

  useEffect(() => {
    setLoading(true)
    http
      .get('/api/comps', {
        sort: query.sort,
        units: true,
        user: true,
        tagsFilter: query.tags,
        type: query.type || type?.value,
        page,
      })
      .then((res) => {
        setLoading(false)
        if (res.error) return alert(res.error)
        if (page === 0) {
          setComps(res.data)
        } else {
          setComps([...comps, ...res.data])
        }
        setPageSize(res.data.length)
      })
  }, [query.sort, hasNewTags, page, JSON.stringify(type)])

  const buildsText = game.getText('build')

  return (
    <div className='home-page container-fluid'>
      <Helmet data={{ sort: query.sort }} />
      <div className='build-page-header capitalize'>
        <h1>
          {game.name} {buildsText}s
        </h1>{' '}
        {typeOptions.length > 0 && (
          <StyledSelect
            value={type}
            onChange={(opt) => {
              query.type = opt.value
              setType(opt)
              navigate({ search: qs.stringify(query) })
            }}
            options={typeOptions}
          />
        )}
      </div>
      <p>
        Look for and post the best pal combinations. Whether it's to build your
        base or show off your team you can see stats, work suitability, and
        locations for bases.
      </p>
      <div className='build-page-bar'>
        <div className='sort-bar'>
          <StyledTabLink
            to='?sort=new'
            active={query.sort === 'new' || !query.sort}
            onClick={() => {
              setPage(0)
            }}
          >
            New
          </StyledTabLink>
          <StyledTabLink
            to='?sort=top'
            active={query.sort === 'top'}
            onClick={() => {
              setPage(0)
            }}
          >
            Top
          </StyledTabLink>
        </div>
        <ButtonLink to={`/${buildsText}s/create`}>
          <span
            style={{ display: 'flex', alignItems: 'center' }}
            className='capitalize'
          >
            <FiPlusCircle style={{ marginRight: '4px', fontSize: '18px' }} />{' '}
            Create {buildsText}
          </span>
        </ButtonLink>
      </div>
      {/* <TagFilter
        type='Comp'
        value={query.tags}
        clearTags={() => navigate({ search: '' })}
        onChange={(newTag) => {
          if (query.tags && query.tags.includes(newTag.id + '')) {
            query.tags = query.tags.filter((t) => t !== newTag.id + '')
            navigate({ search: qs.stringify(query) })
          } else {
            if (!query.tags) query.tags = []
            query.tags.push(newTag.id + '')
            navigate({ search: qs.stringify(query) })
          }
        }}
        all
      /> */}
      <Comps comps={comps} setComps={setComps} />
      {pageSize >= 51 && (
        <div className='load-bar'>
          <button
            className='btn'
            onClick={() => {
              setPage(page + 1)
            }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  )
}

export default Builds
