import { useContext } from 'react'

import GameContext from '../../contexts/GameContext'
import ImageC from '../Image'
import { StyledLink } from '../Styled'

const UnitGrid = ({ units, noLabel, cost }) => {
  const { game } = useContext(GameContext)

  let unitsDisplay = {}
  units.forEach((unit) => {
    if (!unitsDisplay[unit.id]) {
      unitsDisplay[unit.id] = unit
      unitsDisplay[unit.id].count = 1
    } else {
      unitsDisplay[unit.id].count++
    }
  })
  unitsDisplay = Object.keys(unitsDisplay).map((unit) => {
    return unitsDisplay[unit]
  })

  return (
    <div className='unit-grid'>
      {unitsDisplay.map((unit) => {
        const { count } = unit
        return (
          <StyledLink
            to={`/${game.getText('unit')}s/${unit.slug || unit.id}`}
            key={`unit-${unit.id}`}
          >
            <div className='unit-grid-img'>
              <ImageC
                src={
                  unit.img.includes('http')
                    ? unit.img
                    : `https://${game.cdn}${
                        unit.img || `/units/${unit.id}.png`
                      }`
                }
              />
              <div className='unit-grid-count'>x{count}</div>
            </div>
            <div>
              {!game.has('BUILDS:NO_AMOUNT') ? unit.amount : ''}{' '}
              {unit.name + (unit.amount > 1 ? 's' : '')}{' '}
              {unit.properties
                ? unit.properties.map((property) => {
                    return ` ${property.string}`
                  })
                : ''}
            </div>
          </StyledLink>
        )
      })}
    </div>
  )
}

export default UnitGrid
