import { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'
import { can } from 'rbac'

import {
  CgBox,
  CgExtension,
  CgHeart,
  CgToolbox,
  CgTrending,
} from 'react-icons/cg'
import { FiPlusCircle, FiMap } from 'react-icons/fi'

import UserContext from '../../contexts/UserContext'
import GameContext from '../../contexts/GameContext'

import SearchBar from './SearchBar'

const NavbarContainer = styled.div`
  background-color: #102431;
  border-bottom: ${({ theme }) => theme.border};
`

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  a {
    display: inline-block;
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
`

const StyledLogo = styled.div`
  width: 40px;
  margin-right: 10px;
`

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
  overflow: auto;

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }
`

const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 14px 10px;
  border-bottom: 3px solid transparent;
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.2s linear;

  &:hover {
    border-bottom: 3px solid #227aad;
  }

  &[class*='active'] {
    border-bottom: 3px solid #227aad;
  }

  & svg {
    margin-bottom: -2px;
    margin-right: 2px;
  }

  @media only screen and (min-width: 600px) {
    font-size: inherit;
  }
`

const Toggle = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.gradient};
  width: 7em;
  height: 3.5em;
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.toggleBorder};
  font-size: 0.5em;
  padding: 0.25em;
  overflow: hidden;
  cursor: pointer;

  img {
    max-width: 2.5em;
    height: auto;
    transition: all 0.3s linear;

    &:first-child {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translateY(0)' : 'translateY(100px)'};
    }

    &:nth-child(2) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translateY(-100px)' : 'translateY(0)'};
    }
  }
`

const Navbar = (props) => {
  const { user } = useContext(UserContext)
  const { game } = useContext(GameContext)
  // const navigate = useNavigate()

  return (
    <NavbarContainer className='container-fluid'>
      <StyledNavbar>
        <StyledNav>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '24px',
              marginRight: '16px',
            }}
          >
            <StyledLogo>
              <img
                src={`https://${game.cdn}/${game.key}/logo.png`}
                alt='builds icon'
              />
            </StyledLogo>
            {game.siteName}
          </Link>
          <StyledNavLink
            className='capitalize'
            to={`/${game.getText('build')}s`}
          >
            <CgExtension /> {game.getText('build')}s
          </StyledNavLink>
          {!game.has('UNITS:DISABLED') && (
            <>
              {/* <StyledNavLink to='/'> */}
              <StyledNavLink to={`/${game.getText('unit')}s`}>
                <CgBox />{' '}
                <span className='capitalize'>{game.getText('unit')}s</span>
              </StyledNavLink>
              {/* <StyledNavLink to={`/${game.getText('unit')}s`}>
                <CgBox />{' '}
                <span className='capitalize'>{game.getText('unit')}s</span>
              </StyledNavLink> */}
              {game.unitPages &&
                game.unitPages.map((unitPage) => {
                  return (
                    <StyledNavLink
                      to={`/${unitPage.type}`}
                      className='capitalize'
                      key={`unitPage-${unitPage}`}
                    >
                      <CgToolbox /> {unitPage.type}
                    </StyledNavLink>
                  )
                })}
            </>
          )}
          {game.has('SOURCES:ENABLED') && (
            <StyledNavLink to='/sources'>Sources</StyledNavLink>
          )}
          {game.has('USES:ENABLED') && (
            <StyledNavLink to='/events'>Uses</StyledNavLink>
          )}
          {game.has('BREEDING_CALCULATOR:ENABLED') && (
            <StyledNavLink to='/breeding-calculator'>
              <CgHeart /> Breeding Calculator
            </StyledNavLink>
          )}
          {game.has('IV_CALCULATOR:ENABLED') && (
            <StyledNavLink to='/iv-calculator'>
              <CgTrending /> IV Calculator
            </StyledNavLink>
          )}
          {game.has('MAP:ENABLED') && (
            <StyledNavLink to='/map'>
              <FiMap /> Map
            </StyledNavLink>
          )}
        </StyledNav>
        <StyledNav>
          {/* <SearchBar /> */}
          {/* <Toggle
            onClick={props.toggleTheme}
            lightTheme={props.theme === 'light'}
          >
            <img
              src='https://cdn-icons-png.flaticon.com/512/869/869869.png'
              alt='Sun icon'
              title='Sun icon'
            />
            <img
              src='https://cdn-icons-png.flaticon.com/512/547/547433.png'
              alt='moon icon'
              title='Moon icon'
            />
          </Toggle> */}
          {user && can(user, 'ADMIN:PANEL') && (
            <>
              &nbsp;&nbsp;<Link to='/admin'>ADMIN</Link>
            </>
          )}
          &nbsp;&nbsp;
          {user && <Link to={`/profile/${user.id}`}>{user.username}</Link>}
          {!user && (
            <>
              <NavLink to='/login'>Log In</NavLink>&nbsp;&nbsp;
              <NavLink to='/signup' className='btn'>
                Sign Up
              </NavLink>
            </>
          )}
        </StyledNav>
      </StyledNavbar>
    </NavbarContainer>
  )
}

export default Navbar
